import { Link, StaticQuery, graphql } from "gatsby";
import PropTypes from "prop-types";
import React, { useState } from "react";
import classNames from "classnames";
import { useScrollYPosition } from "react-use-scroll-position";
import { Transition } from "react-transition-group";

import Sun from "../components/sun";

import { ReactComponent as Logo } from "../images/rosens-logo.svg";
import { ReactComponent as Hamburger } from "../images/hamburger-menu.svg";
import { ReactComponent as Close } from "../images/x.svg";

const headerButtonClassNames =
  "hidden md:block md:w-64 uppercase font-proxima-nova text-rosens-green-kelp tracking-widest text-lg font-semibold text-center cursor-pointer";

const subHeaderClassNames =
  "flex py-6 bg-rosens-green-kelp text-white font-apertura tracking-wider items-center justify-center absolute w-full";

const headerLinkClassNames =
  "mr-12 transition-colors hover:text-rosens-burning-orange active:text-rosens-burning-orange";

// const headerShrinkBreakpoint = 600;
const headerShrinkBreakpoint = 80;
// this must be AT LEAST as large as the size difference between the expanded & contracted header
const headerHeightDelta = 60;
// const headerGrowBreakpoint = headerShrinkBreakpoint - headerHeightDelta;
const headerGrowBreakpoint = headerShrinkBreakpoint - headerHeightDelta;

function Header() {
  const [isExpanded, toggleExpansion] = useState(false);
  const [desktopExpansion, setDesktopExpansion] = useState(null);
  const [isShortHeader, setShortHeader] = useState(false);
  const scrollY = useScrollYPosition();

  // beacuse the header is sticky, its own height also affects the scroll height. so we need to account for that when determining when to expand/contract it.
  if (!isShortHeader && scrollY > headerShrinkBreakpoint) {
    setShortHeader(true);
  } else if (isShortHeader && scrollY < headerGrowBreakpoint) {
    setShortHeader(false);
  }

  const query = graphql`
    query {
      prismicMetadata {
        data {
          contact_email
          online_ordering_link {
            url
          }
        }
      }
    }
  `;
  return (
    <StaticQuery
      query={query}
      render={data => {
        const {
          prismicMetadata: {
            data: {
              online_ordering_link: { url: onlineOrderingLink },
              contact_email: contactEmail
            }
          }
        } = data;
        return (
          <div
            className={classNames("sticky", "w-screen", "top-0", "z-40", {
              "bg-rosens-marzipan": !isExpanded,
              "bg-rosens-green-kelp": isExpanded,
              "h-screen": isExpanded
            })}
            onMouseLeave={() => setDesktopExpansion(null)}
          >
            <div
              className={classNames(
                "flex py-2 px-6 items-center justify-between",
                isShortHeader ? "md:h-16" : "md:h-32"
              )}
              style={{
                transition: "height 250ms ease"
              }}
            >
              <div className="w-1/4 md:hidden"></div>
              <div className="md:w-full flex flow-row justify-center items-center">
                <div
                  className={classNames(
                    headerButtonClassNames,
                    desktopExpansion === "getBagels" &&
                      "text-rosens-burning-orange"
                  )}
                  onMouseOver={() => setDesktopExpansion("getBagels")}
                >
                  Get Bagels
                </div>
                <Link to="/" className="no-underline">
                  <Logo
                    className={classNames(
                      "md:hidden",
                      "fill-current",
                      isExpanded ? "text-white" : "text-rosens-green-kelp"
                    )}
                    height="52"
                    width="103"
                    viewBox="0 0 213.21 108.19"
                  />
                </Link>
                <Link to="/" className="no-underline">
                  <Logo
                    className={classNames(
                      "hidden md:block fill-current text-rosens-green-kelp",
                      isShortHeader && "md:hidden"
                    )}
                    height="79"
                    width="155"
                    viewBox="0 0 213.21 108.19"
                  />
                  <Sun
                    className={classNames(
                      "text-rosens-burning-orange hidden",
                      isShortHeader && "md:block"
                    )}
                    width="82.5"
                    height="35"
                  />
                </Link>
                <div
                  className={classNames(
                    headerButtonClassNames,
                    desktopExpansion === "ourStory" &&
                      "text-rosens-burning-orange"
                  )}
                  onMouseOver={() => setDesktopExpansion("ourStory")}
                >
                  Our Story
                </div>
              </div>
              <div className="w-1/4 flex items-center justify-end md:hidden">
                <button onClick={() => toggleExpansion(!isExpanded)}>
                  {isExpanded ? (
                    <Close className="fill-current text-white" />
                  ) : (
                    <Hamburger className="text-rosens-green-kelp fill-current" />
                  )}
                </button>
              </div>
            </div>
            {isExpanded && (
              <div className="flex mt-10 px-2 font-apertura">
                <NavColumn>
                  <NavColumnHeader>Get Bagels</NavColumnHeader>
                  <NavColumnMenu>
                    <NavColumnMenuItem>
                      <NavLink to="/locations">Order Now</NavLink>
                    </NavColumnMenuItem>
                    <NavColumnMenuItem>
                      <NavLink to="/locations">Locations</NavLink>
                    </NavColumnMenuItem>
                  </NavColumnMenu>
                </NavColumn>
                <NavColumn>
                  <NavColumnHeader>Our Story</NavColumnHeader>
                  <NavColumnMenu>
                    <NavColumnMenuItem>
                      <NavLink to="/about">How We Do It</NavLink>
                    </NavColumnMenuItem>
                    <NavColumnMenuItem>
                      <NavLink to="/blog">Bagel Bytes</NavLink>
                    </NavColumnMenuItem>
                    <NavColumnMenuItem>
                      <NavLink external to={`mailto:${contactEmail}`}>
                        Get In Touch
                      </NavLink>
                    </NavColumnMenuItem>
                  </NavColumnMenu>
                </NavColumn>
              </div>
            )}
            <Transition timeout={250} in={desktopExpansion === "getBagels"}>
              {state => {
                return (
                  <div
                    className={classNames(
                      subHeaderClassNames,
                      state === "entering" && "visible opacity-100",
                      state === "entered" && "visible opacity-100",
                      state === "exiting" && "opacity-0",
                      state === "exited" && "opacity-0 invisible"
                    )}
                    style={{
                      transition: "opacity 0.25s ease"
                    }}
                  >
                    <Link to="/locations" className={headerLinkClassNames}>
                      Order Now
                    </Link>
                    <Link to="/locations" className={headerLinkClassNames}>
                      Locations
                    </Link>
                  </div>
                );
              }}
            </Transition>
            <Transition timeout={250} in={desktopExpansion === "ourStory"}>
              {state => (
                <div
                  className={classNames(
                    subHeaderClassNames,
                    state === "entering" && "visible opacity-100",
                    state === "entered" && "visible opacity-100",
                    state === "exiting" && "opacity-0",
                    state === "exited" && "opacity-0 invisible"
                  )}
                  style={{
                    transition: "opacity 0.25s ease"
                  }}
                >
                  <Link to="/about" className={headerLinkClassNames}>
                    How We Do It
                  </Link>
                  <Link to="/blog" className={headerLinkClassNames}>
                    Bagel Bytes
                  </Link>
                  <a
                    href={`mailto:${contactEmail}`}
                    className={headerLinkClassNames}
                  >
                    Get In Touch
                  </a>
                </div>
              )}
            </Transition>
          </div>
        );
      }}
    ></StaticQuery>
  );
}

Header.propTypes = {
  siteTitle: PropTypes.string
};

Header.defaultProps = {
  siteTitle: ``
};

function NavColumn({ children }) {
  return <div className="w-1/2 text-white px-4">{children}</div>;
}

function NavColumnHeader({ children }) {
  return (
    <div className="font-wobbly w-full border-b border-gray-600 pb-2">
      {children}
    </div>
  );
}

function NavColumnMenu({ children }) {
  return <ul>{children}</ul>;
}

function NavColumnMenuItem({ children }) {
  return <li className="mt-4">{children}</li>;
}

function NavLink({ children, to, external = false }) {
  const className =
    "no-underline transition-colors active:text-rosens-burning-orange hover:text-rosens-burning-orange";
  return external ? (
    <a href={to} className={className}>
      {children}
    </a>
  ) : (
    <Link to={to} className={className}>
      {children}
    </Link>
  );
}
export default Header;
