import React from "react";
import classNames from "classnames";
import { GatsbyImage } from "gatsby-plugin-image";

export default function DarkenedBackgroundImage(props) {
  const { children, className, image, outerClassName, ...rest } = props;
  return (
    <div
      style={{
        display: "grid"
      }}
    >
      <GatsbyImage
        style={{ gridArea: "1/1" }}
        image={image}
        {...rest}
        className={classNames(outerClassName)}
      />
      <div
        style={{
          gridArea: "1/1",
          position: "relative",
          placeItems: "center",
          display: "grid"
        }}
      >
        <div
          style={{
            background: "rgba(0,0,0,0.2)"
          }}
          className={classNames(`w-full h-full`, className)}
        >
          {children}
        </div>
      </div>
    </div>
  );
}
