import React from "react";
import classNames from "classnames";
import { graphql, StaticQuery } from "gatsby";

import { ReactComponent as InstagramIcon } from "../images/insta.svg";
import { ReactComponent as FacebookIcon } from "../images/facebook.svg";
import { ReactComponent as TwitterIcon } from "../images/twitter.svg";

const socialLinkClassNames =
  "no-underline transition-color hover:text-rosens-burning-orange active:text-rosens-burning-orange";

export default function SocialLinks({ className }) {
  const query = graphql`
    query SocialLinksQuery {
      prismicMetadata {
        data {
          instagram {
            url
          }
          facebook {
            url
          }
          twitter {
            url
          }
        }
      }
    }
  `;
  return (
    <StaticQuery
      query={query}
      render={data => {
        const {
          prismicMetadata: {
            data: {
              instagram: { url: instagramUrl },
              facebook: { url: facebookUrl },
              twitter: { url: twitterUrl }
            }
          }
        } = data;
        return (
          <div className={classNames("flex", className)}>
            <a
              href={instagramUrl}
              className={classNames(socialLinkClassNames, "mr-6")}
            >
              <InstagramIcon
                className="fill-current"
                width="35"
                height="35"
                viewBox="0 0 24 24"
              />
            </a>
            <a
              href={twitterUrl}
              className={classNames(socialLinkClassNames, "mr-6")}
            >
              <TwitterIcon
                className="fill-current"
                width="35"
                height="35"
                viewBox="0 0 24 24"
              />
            </a>
            <a href={facebookUrl} className={socialLinkClassNames}>
              <FacebookIcon
                className="fill-current"
                width="35"
                height="35"
                viewBox="0 0 24 24"
              />
            </a>
          </div>
        );
      }}
    />
  );
}
