import React from "react";
import backgroundPattern from "../images/background-pattern.png";
import classNames from "classnames";
function PatternContainer({ children, className }) {
  return (
    // <div className="m-0 p-0 bg-rosens-green-kelp text-white">
    <div
      className={classNames(
        "m-0 p-0 bg-rosens-green-kelp text-white bg-center br-repeat",
        className
      )}
      style={{
        backgroundSize: "75%",
        backgroundImage: `url(${backgroundPattern})`
      }}
    >
      {children}
    </div>
    // </div>
  );
}

export default PatternContainer;
