import React from "react";
import { ReactComponent as Sun } from "../images/sun.svg";

import classNames from "classnames";

function Logo({ width, height, className }) {
  return (
    <Sun
      className={classNames("fill-current", className)}
      height={height}
      width={width}
      viewBox="0 0 126.32 53.17"
    />
  );
}

export default Logo;
