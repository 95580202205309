import { Link } from "gatsby";
import React from "react";
import classNames from "classnames";

const variants = {
  greenKelp: "text-white bg-rosens-green-kelp",
  white: "text-rosens-green-kelp bg-white",
  burningOrange: "text-white bg-rosens-burning-orange",
  transparent: "bg-transparent border-white border-2"
};

const sizes = {
  big: "p-6 font-wobbly text-xl uppercase w-full md:w-auto tracking-widest",
  normal:
    "py-3 px-4 font-proxima-nova text-sm italic font-semibold tracking-wider",
  small:
    "py-1 px-3 font-proxima-nova text-sm italic font-semibold tracking-wider"
};

function Button({
  to,
  external = false,
  variant = "greenKelp",
  size = "big",
  className,
  children
}) {
  const classString = classNames(
    "inline-block",
    "text-center",
    "hover:bg-white",
    "hover:text-rosens-burning-orange",
    "active:bg-white",
    "active:text-rosens-burning-orange",
    "transition-colors",
    "whitespace-normal",
    variants[variant],
    sizes[size],
    className
  );

  return external ? (
    <a href={to} className={classString}>
      {children}
    </a>
  ) : (
    <Link to={to} className={classString}>
      {children}
    </Link>
  );
}

export default Button;
