import React from "react";
import { ReactComponent as LogoSVG } from "../images/rosens-logo.svg";

import classNames from "classnames";

function Logo({ width, height, className }) {
  return (
    <LogoSVG
      className={classNames("fill-current", className)}
      height={height}
      width={width}
      viewBox="0 0 213.21 108.19"
    />
  );
}

export default Logo;
