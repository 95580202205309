export default function formatPhone(phone) {
  //normalize string and remove all unnecessary characters
  phone = phone.toString().replace(/[^\d]/g, "");

  if (phone.length === 10) {
    return phone.replace(/(\d{3})(\d{3})(\d{4})/, "($1) $2 $3");
  } else {
    console.error(`Could not format phone number: ${phone}`);
    return phone;
  }
}
