import React from "react";
import classNames from "classnames";
import Button from "./button";
import { graphql, Link, StaticQuery } from "gatsby";
import Logo from "./logo";
import PatternContainer from "./pattern-container";
import SocialLinks from "./social-links";
import formatPhone from "../utils/format-phone";
import * as styles from "./footer.module.css";
import DarkenedBackgroundImage from "./darkened-background-image";

function Footer() {
  const query = graphql`
    query {
      prismicLayout {
        data {
          footer_headline
          footer_order_delivery_background_image {
            gatsbyImageData(layout: CONSTRAINED, placeholder: BLURRED)
          }
          footer_view_locations_background_image {
            gatsbyImageData(layout: CONSTRAINED, placeholder: BLURRED)
          }
        }
      }
      fullLocations: allPrismicLocations(
        filter: {
          data: { type: { eq: "Full" }, footer_display: { nin: true } }
        }
      ) {
        edges {
          node {
            id
            data {
              name
              addresslink {
                url
              }
              address1
              address2
              phone
              hours
            }
          }
        }
      }
      prismicMetadata {
        data {
          online_ordering_link {
            url
          }
          contact_email
        }
      }
    }
  `;
  return (
    <StaticQuery
      query={query}
      render={data => {
        const {
          prismicLayout: {
            data: {
              footer_headline: footerHeadline,
              footer_order_delivery_background_image: {
                gatsbyImageData: orderDeliveryBackgroundImage
              },
              footer_view_locations_background_image: {
                gatsbyImageData: viewLocationsBackgroundImage
              }
            }
          },
          fullLocations,
          prismicMetadata: {
            data: {
              online_ordering_link: { url: onlineOrderingLink },
              contact_email: contactEmail
            }
          }
        } = data;
        return (
          <footer className="flex flex-wrap">
            <FeelingHungry
              onlineOrderingLink={onlineOrderingLink}
              headline={footerHeadline}
              className="w-full"
              locationsImage={viewLocationsBackgroundImage}
              orderCateringImage={orderDeliveryBackgroundImage}
            />
            <div className="w-full flex flex-wrap">
              <Nav
                className={classNames(
                  "w-full md:w-1/3",
                  styles.footerNavSection
                )}
                contactEmail={contactEmail}
              />
              <Locations
                fullLocations={fullLocations}
                className={classNames(
                  "w-full md:w-2/3",
                  styles.footerLocationsSection
                )}
              />
            </div>
          </footer>
        );
      }}
    />
  );
}

function FeelingHungry({
  headline,
  onlineOrderingLink,
  className,
  locationsImage,
  orderCateringImage
}) {
  return (
    <section
      className={classNames(
        className,
        "bg-rosens-papaya-whip py-12 px-8 flex flex-col items-center",
        styles.feelingHungrySection
      )}
    >
      <h1 className="text-rosens-green-kelp mb-8">{headline}</h1>
      <div className="flex flex-col w-full whitespace-nowrap md:w-auto md:flex-row md:items-center lg:hidden">
        <Button
          to="/locations"
          className={`mb-4 md:mb-0 md:mr-4 ${styles.feelingHungryButton}`}
        >
          View Locations
        </Button>
        <Button
          to="/locations"
          className={`mb-4 md:mb-0 md:mr-4 ${styles.feelingHungryButton}`}
        >
          Order Now
        </Button>
      </div>
      <div className="lg:relative lg:w-full lg:flex lg:justify-center">
        <div className="lg:absolute flex flex-row w-full max-w-6xl items-center whitespace-nowrap hidden lg:flex lg:justify-center">
          <div className="w-1/3 px-2">
            <DarkenedBackgroundImage
              tag="div"
              image={locationsImage}
              className="flex items-center justify-center py-24"
            >
              <Button
                to="/locations"
                className={`${styles.feelingHungryButton}`}
              >
                View Locations
              </Button>
            </DarkenedBackgroundImage>
          </div>
          <div className="w-1/3 px-2">
            <DarkenedBackgroundImage
              tag="div"
              image={orderCateringImage}
              className="flex items-center justify-center py-24"
            >
              <Button
                to="/locations"
                className={`${styles.feelingHungryButton}`}
              >
                Order Now
              </Button>
            </DarkenedBackgroundImage>
          </div>
        </div>
      </div>
    </section>
  );
}

function Nav({ className, contactEmail }) {
  return (
    <div
      className={classNames(
        "bg-rosens-log-cabin md:flex md:flex-row md:justify-end",
        className
      )}
    >
      <nav
        className={classNames(
          "md:max-w-sm flex-grow text-white py-16 md:pt-0 flex flex-col items-center md:flex-col-reverse"
        )}
      >
        <Logo height="79" width="155" />
        <ul className="my-12 text-center font-apertura md:text-left">
          <li className="my-4">
            <Link to="/about">How We Do It</Link>
          </li>
          <li className="my-4">
            <Link to="/blog">Bagel Bytes</Link>
          </li>
          <li className="my-4">
            <a href={`mailto:${contactEmail}`}>Get In Touch</a>
          </li>
        </ul>
        <SocialLinks />
      </nav>
    </div>
  );
}

function Locations({ fullLocations, className }) {
  return (
    <PatternContainer
      className={classNames(
        className,
        "flex flex-wrap md:justify-start font-apertura py-16 md:py-24 md:px-32"
      )}
    >
      {fullLocations.edges.map(edge => {
        const {
          id,
          data: { name, address1, address2, addresslink, phone, hours }
        } = edge.node;

        return (
          <Location
            key={id}
            name={name}
            address1={address1}
            address2={address2}
            addressUrl={addresslink && addresslink.url}
            phone={phone}
            hours={hours}
            className="w-full md:w-auto md:max-w-sm flex-grow"
          />
        );
      })}
      <div className="mt-16 mb-8 text-sm w-full text-center md:hidden">
        © {new Date().getFullYear()} Rosen's Bagels
      </div>
    </PatternContainer>
  );
}

function Location({
  name,
  address1,
  address2,
  addressUrl,
  phone,
  hours,
  className
}) {
  return (
    <div
      className={classNames(
        className,
        "text-center md:text-left mb-16 leading-loose"
      )}
    >
      <div className="uppercase font-bold">{name}</div>
      <div>
        <a className="underline" href={addressUrl}>
          {address1}
          <br />
          {address2}
        </a>
      </div>
      <div>
        {phone && (
          <a className="underline" href={`tel:${phone}`}>
            {formatPhone(phone)}
          </a>
        )}
      </div>
      <div>{hours}</div>
    </div>
  );
}

export default Footer;
